import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/app/frontend/packages/ui/src/base/Image/Image.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/ui/src/components/Accordion/Accordion.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/image-loader.js");
;
import(/* webpackMode: "eager", webpackExports: ["AddToWishlistButton"] */ "/app/frontend/site/src/components/AddToWishlistButton/AddToWishlistButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CompactAddToCartButton"] */ "/app/frontend/site/src/components/CompactAddToCartButton/CompactAddToCartButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ViewItemListProvider"] */ "/app/frontend/site/src/components/GoogleAnalytics/ViewItemListContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/app/frontend/site/src/components/Link/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContainerWithIntersectionObserver"] */ "/app/frontend/site/src/components/ProductCard/ContainerWithIntersectionObserver.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/lib/store-config/context/storeConfig.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.2_next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwrigh_5nrp6tmwbahbf3fygbuf6deyri/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.2_next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwrigh_5nrp6tmwbahbf3fygbuf6deyri/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.2_next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwrigh_5nrp6tmwbahbf3fygbuf6deyri/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.24.0_next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.4_lbhlxfrtkus52edjbyjept5q7y/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.24.0_next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.4_lbhlxfrtkus52edjbyjept5q7y/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.24.0_next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.4_lbhlxfrtkus52edjbyjept5q7y/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@19._5ffssigtuqhpvo7bbpqgmhjoke/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@19._5ffssigtuqhpvo7bbpqgmhjoke/node_modules/next/dist/client/script.js");
