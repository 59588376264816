"use client";

import * as AccordionPrimitive from "@radix-ui/react-accordion";
import React, {
	type ComponentPropsWithRef,
	type ElementRef,
	forwardRef,
} from "react";
import { Icon } from "../../base/Icon/Icon";
import { cn } from "../../helpers/styles";

export const Accordion = (
	props: React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root>,
) => {
	return (
		<AccordionPrimitive.Root
			{...props}
			className={cn("divide-y border-y", props.className)}
		>
			{props.children}
		</AccordionPrimitive.Root>
	);
};

export const AccordionItem = forwardRef<
	ElementRef<typeof AccordionPrimitive.Item>,
	ComponentPropsWithRef<typeof AccordionPrimitive.Item>
>(({ children, className, ...props }, ref) => {
	return (
		<AccordionPrimitive.Item
			ref={ref}
			className={cn("group/item", className)}
			{...props}
		>
			{children}
		</AccordionPrimitive.Item>
	);
});

AccordionItem.displayName = "AccordionItem";

export const AccordionTrigger = forwardRef<
	ElementRef<typeof AccordionPrimitive.Trigger>,
	ComponentPropsWithRef<typeof AccordionPrimitive.Trigger>
>(({ children, className, ...props }, ref) => {
	return (
		<AccordionPrimitive.Trigger
			ref={ref}
			className={cn(
				"flex min-h-12 w-full items-center justify-between",
				className,
			)}
			{...props}
		>
			{children}
		</AccordionPrimitive.Trigger>
	);
});

AccordionTrigger.displayName = "AccordionTrigger";

export const AccordionHeader = forwardRef<
	ElementRef<typeof AccordionPrimitive.Header>,
	ComponentPropsWithRef<typeof AccordionPrimitive.Header>
>(({ children, className, ...props }, ref) => {
	return (
		<AccordionPrimitive.Header
			ref={ref}
			className={cn("font-bold text-sm md:text-base", className)}
			{...props}
		>
			{children}
		</AccordionPrimitive.Header>
	);
});

AccordionHeader.displayName = "AccordionHeader";

export const AccordionChevron = forwardRef<
	ElementRef<typeof Icon>,
	Omit<ComponentPropsWithRef<typeof Icon>, "icon">
>((props, ref) => {
	return (
		<Icon
			ref={ref}
			{...props}
			className="transition-transform duration-200 group-data-[state=open]/item:rotate-180"
			icon="chevron-down"
		/>
	);
});

AccordionChevron.displayName = "AccordionChevron";

export const AccordionContent = forwardRef<
	ElementRef<typeof AccordionPrimitive.Content>,
	ComponentPropsWithRef<typeof AccordionPrimitive.Content>
>(({ children, className, ...props }, ref) => {
	return (
		<AccordionPrimitive.Content
			ref={ref}
			className={cn(
				"flex flex-col text-sm group-data-[state=closed]/item:animate-accordion-up group-data-[state=open]/item:animate-accordion-down md:text-base",
				className,
			)}
			{...props}
		>
			{children}
		</AccordionPrimitive.Content>
	);
});

AccordionContent.displayName = "AccordionContent";
