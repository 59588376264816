"use client";

import type { GTMCommerceItemProps } from "@evolve-storefront/gtm-ecommerce/types";
import { ProductCard } from "@evolve-storefront/ui/components/ProductCard/ProductCard";
import type { ReactNode } from "react";
import type { ComponentPropsWithoutRef } from "react";
import { useInView } from "react-intersection-observer";
import { useViewItemList } from "~/components/GoogleAnalytics/ViewItemListContext";

type Props = ComponentPropsWithoutRef<"article"> & {
	gaProductItem: GTMCommerceItemProps;
	className?: string;
};

/**
 * A container that tracks when the product is in view and sends the event to Google Analytics using the `ViewItemListContext`.
 */
export const ContainerWithIntersectionObserver = ({
	className,
	gaProductItem,
	children,
}: Props): ReactNode => {
	const { setProductInView } = useViewItemList();
	const { ref } = useInView({
		triggerOnce: true,
		onChange: (inView) => {
			if (inView) {
				// TODO: Re-enable once GTM is set up since error is annoying (possibly hide behind GTM tag available on page)
				// setProductInView && gaProductItem && setProductInView(gaProductItem);
			}
		},
	});

	return (
		<ProductCard ref={ref} className={className}>
			{children}
		</ProductCard>
	);
};
