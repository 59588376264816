"use client";

import { Button } from "@evolve-storefront/ui/base/Button";
import { Icon } from "@evolve-storefront/ui/base/Icon/Icon";
import { cn } from "@evolve-storefront/ui/helpers/styles";
import { byMax } from "@labdigital/toolkit";
import type { ReactNode } from "react";
import React from "react";
import { useCart } from "~/app/[locale]/(main)/cart/_hooks/use-cart";
import { useAddToCartContext } from "~/components/AddToCartContext";
import { useAddLineItems } from "~/hooks/cart/useAddLineItems";

type Props = {
	sku: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
	React.AnchorHTMLAttributes<HTMLAnchorElement>;

export function CompactAddToCartButton({
	sku,
	className,
	...attributes
}: Props): ReactNode {
	const { cart } = useCart();
	const { mutate: addLineItems } = useAddLineItems();
	const { setAddedLineItem, setErrorOpen } = useAddToCartContext();

	const addToCart = async (sku: string) => {
		addLineItems(
			{
				id: cart?.id,
				lineItems: [{ sku, quantity: 1 }],
			},
			{
				onSuccess: (data) => {
					const lineItem = data?.data?.cartLineItemsAdd.lineItems
						.filter((l) => l?.variant.sku === sku)
						.reduce(
							byMax((l) =>
								l.addedAt ? new Date(l.addedAt).getTime() : undefined,
							),
						);

					if (lineItem) {
						setAddedLineItem(lineItem);
					}
				},
				onError: () => {
					setErrorOpen(true);
				},
			},
		);
	};

	return (
		<Button
			{...attributes}
			variant="tertiary"
			className={cn(
				"inline-flex gap-1 rounded-full border-2 border-gray-200 px-4",
				className,
			)}
			onClick={() => void addToCart(sku)}
		>
			<Icon icon="plus" />
			<Icon icon="shopping-cart" />
		</Button>
	);
}
